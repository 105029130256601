<template>
  <minePage
    :info="{}"
    barTitle="我的订单"
    :barOptions="barOptions"
    :cellOptions="cellOptions"
    @barChange="barChange"
    @cellChange="cellChange"
    @onBarTitle="onBarTitle"
  ></minePage>
</template>

<script>
import minePage from "@components/minePage";
export default {
  name: "mine",
  components: { minePage },
  data() {
    return {
      barOptions: [
        {
          label: "预约",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-1.png"),
          badge: 0,
          page: "mindReservation",
          key: "booked",
        },
        {
          label: "扫码借用",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-2.png"),
          badge: 0,
          page: "deviceScanningList",
          key: "passed",
        },
        {
          label: "保养消毒",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-3.png"),
          badge: 0,
          page: "disinfectList",
          key: "uncleaned",
        },
        {
          label: "归还",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-4.png"),
          badge: 0,
          page: "giveBackList",
          key: "revert",
        },
        {
          label: "报障",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-5.png"),
          badge: 0,
          page: "deviceFailureList",
          key: "unusable",
        },
      ],
      cellOptions: [
        {
          label: "预约记录",
          icon: require("@/icons/imgs/workManager-mine-cell-1.png"),
          page: "reservation",
        },
        {
          label: "借用记录",
          icon: require("@/icons/imgs/workManager-mine-cell-2.png"),
          page: "borrow",
        },
        {
          label: "报障记录",
          icon: require("@/icons/imgs/workManager-mine-cell-3.png"),
          page: "breakdown",
        },
        {
          label: "费用记录",
          icon: require("@/icons/imgs/workManager-mine-cell-4.png"),
          page: "cost",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getCount()
  },
  methods: {
    getCount() {
      this.$api.deviceBorrowModule
        .count3({ startTime: "", endTime: "" })
        .then((res) => {
          let _data = res.data;
          this.barOptions.map((item) => {
            item.badge = _data[item.key];
            return item;
          });
        })
        .catch((e) => {});
    },
    barChange(e) {
      this.$push("/deviceBorrow/borrowTabsPage/" + e.page);
    },
    cellChange(e) {
      this.$push("/deviceRecord/" + e.page);
    },
    onBarTitle(){
       this.$push("/deviceBorrow/borrowTabsPage/allDeviceList");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
</style>
